import { useStore } from 'vuex';
import { ProductionType } from '@/hooks/useConstructor';
import { Debtor } from '@/hooks/useDebtors';
import {
  ApiCommand, ApiRequest,
  ApiResponse,
  ListingRequestSource,
  ListingResponse,
  OrderDirection,
} from '@/store/modules/api';
import { useApi } from '@/hooks/useApi';
import { formatListingRequest, formatListingResponse } from '@/components/activeTable/useActiveTable';
import { dateToApiDate } from '@/utils/date';
import { Company } from '@/hooks/useCompanies';
import DebtorAgreement from '@/components/dialog/dialogs/debtorAgreement/DebtorAgreement.vue';
import { commonLegacyApiRequest } from '@core/service/commonService';

export type HasDebtorId = {
  debtor_id: Debtor['pk'];
}

type CommonDebtorSignalModel = ListingRequestSource<{
  signal: any;
} & HasDebtorId>

export type FetchGeneralDocumentFlowResponse = Array<any>

export type FetchGeneralDocumentFlowModel = ListingRequestSource<{
  production_type: ProductionType;
  signal: any;
} & HasDebtorId>

export type FetchHousebookModel = CommonDebtorSignalModel
export type FetchHousebookResponse = ListingResponse<any>

export type FetchEgrnDataModel = CommonDebtorSignalModel
export type FetchEgrnDataResponse = ListingResponse<any>

export type FetchEgrnRightsModel = CommonDebtorSignalModel
export type FetchEgrnRightsResponse = ListingResponse<any>

export type FetchFeePaymentsModel = FetchGeneralDocumentFlowModel
export type FetchFeePaymentsResponse = ListingResponse<any>

type FeePaymentCommon = {
  document_number: string;
  document_amount: string;
  document_date: Date;
}

export type FeePayment = FeePaymentCommon & {
  id: number;
  production_type: ProductionType;
  file: string;
  extra: Record<string, any>;
  oktmo: string;
  recipient_inn: string;
  recipient_kpp: string;
  address_norm: string;
  debtor_name: string;
}

export type FeePaymentModel = FeePaymentCommon & {
  file: File | string | null;
  extra: string;
  oktmo?: string;
  recipient_inn?: string;
  recipient_kpp?: string;
  address_norm?: string;
  debtor_name?: string;
  need_return_payment?: boolean;
}

export type FetchFeePaymentModel = {
  id: number;
} & HasDebtorId;

export type CreateFeePaymentModel = FeePaymentModel & {
  debtor_id: Debtor['pk'];
  production_type: ProductionType;
}

export type UpdateFeePaymentModel =
  & Partial<FeePaymentModel>
  & {
    id: number;
    production_type: ProductionType;
  }
  & HasDebtorId;

export type DeleteFeePaymentModel = FetchFeePaymentModel;

export type FetchJudgmentsModel = CommonDebtorSignalModel;

export type FetchJudgmentsResponse = ListingResponse<any>

export type FetchSmsModel = CommonDebtorSignalModel;

export type FetchSmsResponse = ListingResponse<any>

export type FetchVoiceModel = ListingRequestSource<{
  has_send_at: boolean;
  signal: any;
} & HasDebtorId>

export type FetchVoiceResponse = ListingResponse<any>

export type FetchFsspHistoryModel = ListingRequestSource<HasDebtorId & {
  id: number;
  created_at: Date;
  has_files: true;
}>

export type FetchFnsHistoryModel = ListingRequestSource<{
  debtor_ids: Debtor['pk'][] | Debtor['pk'];
  signal: any;
}>

export type FetchFnsHistoryResponse = ListingResponse<any>
export type FetchFsspHistoryResponse = ListingResponse<any>

export type FetchBankHistoryModel = CommonDebtorSignalModel;

export type FetchBankHistoryResponse = ListingResponse<any>

export type FetchWritsOfExecutionModel = ListingRequestSource<{
  debtor_ids: Debtor['pk'][];
  company_id: Company['id'];
  signal: any;
}>

export type FetchWritsOfExecutionResponse = ListingResponse<Record<string, string> & {
  end_reason1: string;
  end_reason2: string;
  end_reason3: string;
  date_from: string;
  date_to: string;
}>

export type FetchMyDocumentsModel = CommonDebtorSignalModel;

export type FetchMyDocumentsResponse = ListingResponse<any>

export type UploadMyDocumentsModel = {
  debtor: Debtor['pk'];
  production_type: ProductionType;
  file: File;
  key?: string;
  created_at?: string;
  date?: string;
  number?: string;
  estate_object: null;
  company: number;
}

export type UploadMyDocumentsResponse = any;

export type UploadHousebookDocumentsModel = {
  debtor: Debtor['pk'];
  production_type: ProductionType;
  file: File;
  document_formation_date: string;
  can_be_attached: boolean;
  company: number;
}

export type UploadHousebookDocumentsResponse = any;

export type RemoveDocumentModel = {
  id: number;
  debtor_id?: Debtor['pk'];
}

export type DocumentImagesModel = ListingRequestSource<
  { active?: string; debtor_id: number }
  >

export type DocumentImage = {
  id: number;
  status_name: string;
  created_at: Date;
  updated_at: Date;
  production_type: ProductionType;
  file: string;
  debtor: Debtor['pk'];
}

export type RemoveMyDocumentsResponse = undefined;

export type UniqueDebtorsDocumentsModel = ListingRequestSource<{
  key: 'protocol_ooc';
  debtor_id: Debtor['pk'];
}>

export type RemoveDocumentApiCommand = ApiCommand.removeMyDocument
  | ApiCommand.removeGeneralDocument
  | ApiCommand.removeEgrnDocument
  | ApiCommand.removeEgrnTransferOfRights
  | ApiCommand.removeHouseBookDocument
  | ApiCommand.removeUniqueDebtorsDocument
  | ApiCommand.removeDebtorPochtaTrack
  | ApiCommand.deleteFeePayment

export type DebtorPochtaTrack = {
  production_type: ProductionType;
  debtor: Debtor['debtor']['pk'];
  number: string;
  weight: number;
  created_at: string;
  id: number;
  file: File;
  file_f103: File;
  attachment: File;
}

export type CreateDebtorPochtaTrackModel = {
  production_type: ProductionType;
  number: string;
  weight: number;
  file: File;
  file_f103: File;
  debtor: Debtor['debtor']['pk'];
}

export type UpdateDebtorPochtaTrackModel = { id: number; debtor: Debtor['debtor']['pk'] } & Partial<CreateDebtorPochtaTrackModel>;

export type DeleteDebtorPochtaTrackModel = { id: number };

export type FetchDebtorPochtaTracksModel = ListingRequestSource<{
  production_type: ProductionType;
  debtor_id: Debtor['pk'];
  signal: any;
}>

export type DebtorCourtDecisionModel = {
  id: number;
  debtor_pk: string;
  production_type: ProductionType;
  file: File;
  court_name: string;
  decision_date: string|null;
  case_number: string;
  debtor_name: string;
  address: string;
  birth_place: string;
  birth_date: string|null;
  amount: number;
  penalties: number;
  duty: number;
  passport: string;
  snils: string;
  account_number: string;
}

export type DebtorAgreementModel = {
  id: number;
  number: string;
  created_at: string;
  valid_from: string;
  valid_until: string;
  debtor: number;
  file?: File | string | null;
}

export type DebtorExtraAgreementModel = {
  id: number;
  number: number;
  created_at: string;
  valid_from: string;
  valid_until: string;
  amount: number;
  agreement: number;
}

export type DebtorAgreement = DebtorAgreementModel & {
  extra_agreements: DebtorExtraAgreementModel[];
}

export type DebtorDocumentType = {
  id: number;
  production_type: ProductionType;
  created_at: Date;
  updated_at: Date;
  company: Company['id'];
  is_show: boolean;
  order_number: number;
  name: string;
}

export type DebtorCustomDocumentType = DebtorDocumentType;
export type DebtorDefaultDocumentType = DebtorDocumentType & { key: string }

export type FetchDebtorDocumentTypesModel = ListingRequestSource<{
  company: Company['id'];
  production_type: ProductionType;
  order_number?: number;
}>

export type FetchDebtorCustomDocumentTypesResponse = ListingResponse<DebtorCustomDocumentType>;
export type FetchDebtorDefaultDocumentTypesResponse = ListingResponse<DebtorDefaultDocumentType>;

export type DebtorCustomDocument = {
  id: number;
  production_type: ProductionType;
  created_at: Date;
  updated_at: Date;
  file: File | string | null;
  debtor: number;
  type: number;
}

export type FetchDebtorCustomDocumentsModel = ListingRequestSource<{
  production_type: ProductionType;
  type: DebtorCustomDocumentType['id'];
}> & HasDebtorId;

export const useDocumentsApi = () => {
  const store = useStore();
  const api = useApi();

  const fetchDebtorDefaultDocumentTypes = async (
    model: FetchDebtorDocumentTypesModel,
  ): Promise<ApiResponse<FetchDebtorDefaultDocumentTypesResponse>> => api({
    command: ApiCommand.fetchDebtorDefaultDocumentTypes,
    params: {
      ...formatListingRequest({
        ...model,
        ordering: [{
          key: 'order_number',
          direction: OrderDirection.asc,
        }],
      }),
    },
  });

  const createDebtorDefaultDocumentType = (
    data: Omit<DebtorDefaultDocumentType, 'id' | 'created_at' | 'updated_at' | 'name'>,
  ) => api<DebtorDefaultDocumentType>({
    command: ApiCommand.createDebtorDefaultDocumentType,
    data,
  });

  const updateDebtorDefaultDocumentType = (
    data: DebtorCustomDocumentType,
  ) => api<DebtorCustomDocumentType>({
    command: ApiCommand.updateDebtorDefaultDocumentType,
    params: {
      id: data.id,
    },
    data,
  });

  const deleteDebtorDefaultDocumentType = (id: number) => api<boolean>({
    command: ApiCommand.updateDebtorDefaultDocumentType,
    params: { id },
  });

  const fetchDebtorCustomDocumentTypes = async (
    model: FetchDebtorDocumentTypesModel,
  ): Promise<ApiResponse<FetchDebtorCustomDocumentTypesResponse>> => api({
    command: ApiCommand.fetchDebtorCustomDocumentTypes,
    params: {
      ...formatListingRequest({
        ...model,
        ordering: [{
          key: 'order_number',
          direction: OrderDirection.asc,
        }],
      }),
    },
  });

  const createDebtorCustomDocumentType = (
    data: Omit<DebtorCustomDocumentType, 'id' | 'created_at' | 'updated_at'>,
  ) => api<DebtorCustomDocumentType>({
    command: ApiCommand.createDebtorCustomDocumentType,
    data,
  });

  const updateDebtorCustomDocumentType = (
    data: DebtorCustomDocumentType,
  ) => api<DebtorCustomDocumentType>({
    command: ApiCommand.updateDebtorCustomDocumentType,
    params: {
      id: data.id,
    },
    data,
  });

  const deleteDebtorCustomDocumentType = (id: number) => api<boolean>({
    command: ApiCommand.deleteDebtorCustomDocumentType,
    params: { id },
  });

  const fetchDebtorCustomDocuments = (model: FetchDebtorCustomDocumentsModel) => api({
    command: ApiCommand.fetchDebtorCustomDocuments,
    params: formatListingRequest(model),
  });

  const uploadDebtorCustomDocument = (data: Omit<DebtorCustomDocument, 'id'>) => api({
    command: ApiCommand.uploadDebtorCustomDocument,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const deleteDebtorCustomDocument = (id: number) => api({
    command: ApiCommand.deleteDebtorCustomDocument,
    params: {
      id,
    },
  });

  const fetchGeneralDocumentsFlow = async (
    model: FetchGeneralDocumentFlowModel,
  ): Promise<ApiResponse<FetchGeneralDocumentFlowResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchGeneralDocumentsFlow', model);

    return { status, response };
  };

  const fetchHousebook = async (
    model: FetchHousebookModel,
  ): Promise<ApiResponse<FetchHousebookResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchHousebook', model);

    return { status, response };
  };

  const fetchEgrnData = async (
    model: FetchEgrnDataModel,
  ): Promise<ApiResponse<FetchEgrnDataResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchEgrnData', model);

    return { status, response };
  };

  const fetchEgrnRights = async (
    model: FetchEgrnRightsModel,
  ): Promise<ApiResponse<FetchEgrnRightsResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchEgrnRights', model);

    return { status, response };
  };

  const fetchFeePayments = async (
    model: FetchFeePaymentsModel,
  ): Promise<ApiResponse<FetchFeePaymentsResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchFeePayments', model);

    return {
      status,
      response: {
        ...response,
        results: response.results.map((value: { status: string }) => ({
          ...value,
          status: value.status === 'paid' ? 'Оплата пошлины' : 'Доплата пошлины',
        })),
      },
    };
  };

  const fetchJudgments = async (
    model: FetchJudgmentsModel,
  ): Promise<ApiResponse<FetchJudgmentsResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchJudgments', model);

    return { status, response };
  };

  const fetchSms = async (
    model: FetchSmsModel,
  ): Promise<ApiResponse<FetchSmsResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchSms', model);

    return { status, response };
  };

  const fetchVoice = async (
    model: FetchVoiceModel,
  ): Promise<ApiResponse<FetchVoiceResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchVoice', model);

    return { status, response };
  };

  const fetchFnsHistory = async (
    model: FetchFnsHistoryModel,
  ): Promise<ApiResponse<FetchFnsHistoryResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchFnsHistory', model);

    return { status, response };
  };

  const fetchFsspHistoryDocuments = async (
    model: FetchFsspHistoryModel,
  ): Promise<ApiResponse<FetchFsspHistoryResponse>> => api({

    command: ApiCommand.fetchFsspDocumentsHistory,
    params: {
      has_files: true,
      ...formatListingRequest({
        ...model,
        ordering: [{
          key: 'created_at',
          direction: OrderDirection.asc,
        }],
      }),
    },
    signal: model.signal,

  }).then(async (response) => {
    if (!response.status) return response;

    return {
      status: true,
      response: {
        ...response.response,
        results: response.response.results.reduce((files: any[], record: any) => {
          files.push(...record.files.map((file: any) => ({
            ...file,
            action: record.action,
            order_id: record.order_id,
            received_at: new Date(file.created_at),
            request_type: file.file_type === 'request' ? 'Запрос' : 'Ответ',
          })));
          return files;
        }, []),
      },
    };
  });

  const fetchBankHistory = async (
    model: FetchBankHistoryModel,
  ): Promise<ApiResponse<FetchBankHistoryResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchBankHistory', model);

    return { status, response };
  };

  const fetchWritsOfExecution = async (
    model: FetchWritsOfExecutionModel,
  ): Promise<ApiResponse<FetchWritsOfExecutionResponse>> => {
    const { status, response } = await store.dispatch(
      'documents/fetchWritsOfExecution', model,
    ) as ApiResponse<FetchWritsOfExecutionResponse>;

    return { status, response };
  };

  const fetchMyDocuments = async (
    model: FetchMyDocumentsModel,
  ): Promise<ApiResponse<FetchMyDocumentsResponse>> => {
    const { status, response } = await store.dispatch('documents/fetchMyDocuments', model);

    return { status, response };
  };

  const uploadMyDocument = async (
    model: UploadMyDocumentsModel,
  ): Promise<ApiResponse<UploadMyDocumentsResponse>> => {
    const { status, response } = await store.dispatch('documents/uploadMyDocument', model);

    return { status, response };
  };

  const uploadHousebookDocument = async (
    model: UploadHousebookDocumentsModel,
  ): Promise<ApiResponse<UploadHousebookDocumentsResponse>> => {
    const { status, response } = await store.dispatch('documents/uploadHousebookDocument', model);

    return { status, response };
  };

  const removeDocument = (
    apiCommand: RemoveDocumentApiCommand,
    model: RemoveDocumentModel,
  ) => api<RemoveMyDocumentsResponse>({
    command: apiCommand,
    params: model,
  });

  const fetchDocumentsImages = (
    model: DocumentImagesModel,
  ) => api({
    command: ApiCommand.fetchDocumentsImages,
    params: formatListingRequest(model),
    signal: model.signal,
  }).then((response) => {
    if (!response.status) return response;
    return {
      status: true,
      response: formatListingResponse<DocumentImage>({
        ...response.response,
        results: response.response.results.map((record: any) => ({
          ...record,
          created_at: new Date(record.created_at),
          updated_at: new Date(record.updated_at),
        }) as DocumentImage),
      }),
    };
  });

  const fetchUniqueDebtorsDocuments = (
    model: UniqueDebtorsDocumentsModel,
  ) => api({
    command: ApiCommand.fetchUniqueDebtorsDocuments,
    params: formatListingRequest(model),
    signal: model.signal,
  }).then((response) => {
    if (!response.status) return response;
    return {
      status: true,
      response: formatListingResponse({
        ...response.response,
        results: response.response.results.map((record: any) => ({
          ...record,
          created_at: new Date(record.created_at),
          updated_at: new Date(record.updated_at),
        }) as DocumentImage),
      }),
    };
  });

  const uploadUniqueDebtorsDocument = async (
    model: UploadMyDocumentsModel,
  ): Promise<ApiResponse<UploadMyDocumentsResponse>> => {
    const { status, response } = await api({
      command: ApiCommand.uploadUniqueDebtorsDocument,
      data: model,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return { status, response };
  };

  const fetchDebtorPochtaTracks = (
    model: FetchDebtorPochtaTracksModel,
  ): Promise<ApiResponse<DebtorPochtaTrack[]|any>> => api<ListingResponse<DebtorPochtaTrack>>({
    command: ApiCommand.fetchDebtorPochtaTracks,
    params: formatListingRequest({
      ...model,
      filters: {
        ...model.filters,
        debtor: model.filters?.debtor_id,
      },
    }),
    signal: model.signal,
  }).then((response) => {
    if (!response.status) return response;
    return {
      status: true,
      response: formatListingResponse({
        ...response.response,
        results: response.response.results.map((r) => ({
          ...r,
          created_at: new Date(r.created_at),
        })),
      }),
    };
  });

  const fetchDebtorPochtaTrack = (
    id: DebtorPochtaTrack['id'],
  ) => api<DebtorPochtaTrack>({
    command: ApiCommand.fetchDebtorPochtaTrack,
    params: { id },
  }).then((response) => response);

  const createDebtorPochtaTrack = (
    model: CreateDebtorPochtaTrackModel,
  ): Promise<ApiResponse<DebtorPochtaTrack>> => api({
    command: ApiCommand.createDebtorPochtaTrack,
    data: {
      ...model,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateDebtorPochtaTrack = (
    model: UpdateDebtorPochtaTrackModel,
  ): Promise<ApiResponse<DebtorPochtaTrack>> => api({
    command: ApiCommand.updateDebtorPochtaTrack,
    params: {
      id: model.id,
    },
    data: {
      ...model,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const removeDebtorPochtaTrackModel = (
    model: DeleteDebtorPochtaTrackModel,
  ) => api({
    command: ApiCommand.removeDebtorPochtaTrack,
    params: {
      id: model.id,
    },
  });

  const fetchFeePayment = (
    model: FetchFeePaymentModel,
  ) => api<FeePayment>({
    command: ApiCommand.fetchFeePayment,
    params: {
      debtor_id: model.debtor_id,
      id: model.id,
    },
  }).then((response) => {
    if (!response.status) return response;
    return {
      status: true,
      response: {
        ...response.response,
        document_date: new Date(response.response.document_date),
      },
    };
  });

  const createFeePayment = (
    model: CreateFeePaymentModel,
  ) => api<FeePayment>({
    command: ApiCommand.createFeePayment,
    params: {
      debtor_id: model.debtor_id,
    },
    data: {
      ...model,
      document_date: dateToApiDate(model.document_date),
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateFeePayment = (
    model: UpdateFeePaymentModel,
  ) => api<FeePayment>({
    command: ApiCommand.updateFeePayment,
    params: {
      debtor_id: model.debtor_id,
      id: model.id,
    },
    data: {
      ...model,
      ...(model.document_date
        ? {
          document_date: typeof model.document_date !== 'string'
            ? dateToApiDate(model.document_date)
            : model.document_date,
        }
        : {}
      ),
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const deleteFeePayment = (
    model: DeleteFeePaymentModel,
  ) => api<any>({
    command: ApiCommand.deleteFeePayment,
    params: {
      debtor_id: model.debtor_id,
      id: model.id,
    },
  });

  const fetchDebtorAgreements = (
    model: { filters: { debtor_id: number; company_id: number }; signal: any },
  ) => api<any>({
    command: ApiCommand.fetchDebtorAgreements,
    params: formatListingRequest({
      ...model,
      page: 1,
      limit: 1000,
    }),
    signal: model.signal,
  });

  const fetchDebtorAgreement = (
    id: number,
  ) => api<DebtorAgreement>({
    command: ApiCommand.fetchDebtorAgreement,
    params: { id },
  });

  const fetchFsspDetails = async (
    company_id: Company['id'], id: number,
  ): Promise<ApiResponse> => {
    const { status, response } = await commonLegacyApiRequest({
      command: ApiCommand.fetchFsspDetails,
      params: { company_id, id },
    } as ApiRequest<any>);
    return {
      status,
      response,
    };
  };

  const updateFsspDetails = async (
    id: number,
    company_id: Company['id'],
    model: any,
  ) => commonLegacyApiRequest({
    command: ApiCommand.updateFsspDetails,
    data: model,
    params: { id, company_id },
  });

  const createDebtorAgreement = (
    model: Omit<DebtorAgreementModel, 'id'>,
  ) => api<DebtorAgreementModel>({
    command: ApiCommand.createDebtorAgreement,
    data: model,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateDebtorAgreement = (
    model: DebtorAgreementModel,
  ) => api<DebtorAgreementModel>({
    command: ApiCommand.updateDebtorAgreement,
    data: model,
    params: {
      id: model.id,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const deleteDebtorAgreement = (id: number) => api<boolean>({
    command: ApiCommand.deleteDebtorAgreement,
    params: { id },
  });

  const createDebtorExtraAgreement = (
    model: Omit<DebtorExtraAgreementModel, 'id'>,
  ) => api<DebtorAgreementModel>({
    command: ApiCommand.createDebtorExtraAgreement,
    data: model,
  });

  const updateDebtorExtraAgreement = (
    model: DebtorExtraAgreementModel,
  ) => api<DebtorAgreementModel>({
    command: ApiCommand.updateDebtorExtraAgreement,
    data: model,
    params: {
      id: model.id,
    },
  });

  const deleteDebtorExtraAgreement = (id: number) => api<boolean>({
    command: ApiCommand.deleteDebtorExtraAgreement,
    params: { id },
  });

  const fetchDebtorCourtDecision = (
    model: { id: number; debtor_id: number },
  ) => api<any>({
    command: ApiCommand.fetchDebtorCourtDecision,
    params: {
      debtor_id: model.debtor_id,
      id: model.id,
    },
  });

  const addDebtorCourtDecision = (
    model: Omit<DebtorCourtDecisionModel, 'id'>,
  ) => api<any>({
    command: ApiCommand.addDebtorCourtDecision,
    params: {
      debtor_id: model.debtor_pk,
    },
    data: model,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const updateDebtorCourtDecision = (
    model: DebtorCourtDecisionModel,
  ) => api<any>({
    command: ApiCommand.updateDebtorCourtDecision,
    params: {
      debtor_id: model.debtor_pk,
      id: model.id,
    },
    data: model,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {
    fetchDebtorDefaultDocumentTypes,
    createDebtorDefaultDocumentType,
    updateDebtorDefaultDocumentType,
    deleteDebtorDefaultDocumentType,
    fetchDebtorCustomDocumentTypes,
    createDebtorCustomDocumentType,
    updateDebtorCustomDocumentType,
    deleteDebtorCustomDocumentType,
    fetchDebtorCustomDocuments,
    uploadDebtorCustomDocument,
    deleteDebtorCustomDocument,

    fetchGeneralDocumentsFlow,
    fetchHousebook,
    fetchEgrnData,
    fetchEgrnRights,
    fetchJudgments,
    fetchSms,
    fetchVoice,
    fetchFnsHistory,
    fetchFsspHistoryDocuments,
    fetchBankHistory,
    fetchWritsOfExecution,
    fetchMyDocuments,
    uploadMyDocument,
    uploadHousebookDocument,
    removeDocument,
    fetchDocumentsImages,
    fetchUniqueDebtorsDocuments,
    uploadUniqueDebtorsDocument,
    fetchDebtorPochtaTracks,
    fetchDebtorPochtaTrack,
    createDebtorPochtaTrack,
    updateDebtorPochtaTrack,
    removeDebtorPochtaTrackModel,

    fetchFeePayments,
    fetchFeePayment,
    createFeePayment,
    updateFeePayment,
    deleteFeePayment,

    fetchDebtorAgreements,
    fetchDebtorAgreement,
    fetchFsspDetails,
    updateFsspDetails,
    createDebtorAgreement,
    updateDebtorAgreement,
    deleteDebtorAgreement,
    createDebtorExtraAgreement,
    updateDebtorExtraAgreement,
    deleteDebtorExtraAgreement,

    fetchDebtorCourtDecision,
    addDebtorCourtDecision,
    updateDebtorCourtDecision,
  };
};
