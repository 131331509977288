import { ref, Ref } from 'vue';
import { CompanySettingsSendType } from '@/hooks/useCompanies';
import { ActiveFormField } from '@/hooks/useActiveForm';
import { Employee } from '@/hooks/useEmployees';
import { usePreventLeaveTrigger } from '@/hooks/usePreventLeave';
import {
  CompanySettingsJudicial,
  JudicialAutomationModel,
} from '@/components/automation/judicial/types';
import { toModel } from '@/components/automation/judicial/conversions/settingsToModel';
import { toSettings } from '@/components/automation/judicial/conversions/modelToSettings';
import { LabelValue } from '@/components/automation/judicial/tabs/types';

export const useJudicialModel = (
  employees: Ref<Employee[]>,
) => {
  const leaveController = usePreventLeaveTrigger();

  const model = ref<JudicialAutomationModel>({
    auto_correct_debt_period: false,
    judicial_automation_enabled: false,
    auto_filing_claim: false,
    filling_claim_period: null,
    amount_range: [0, 100000],
    debtors_amount: 0,
    send_type: CompanySettingsSendType.send_to_court,
    amount_setting: false,
    court_test: false,
    auto_attach_extracts_from_egrn: false,
    need_rosreestr_discharge: false,
    auto_rosreestr_discharge: false,
    rosreestr_discharge_period: null,
    auto_rosreestr_discharge_period_force: false,
    rosreestr_discharge_period_force: null,
    pik_sale_documents_period: null,
    pik_sale_documents: false,
    // rosreestr_discharge_period_days: [],
    // rosreestr_discharge_period_hour: null,
    auto_executive_transfer: false,
    rosreestr_characteristics: false,
    rosreestr_movement: false,
    unknown_owner_rosreestr_movement: false,

    auto_fees_discharge: false,
    fees_discharge_period: null,
    employees: [],
    notify_emails: [],
    without_fee: false,
    auto_filing_complaint: false,
    auto_filing_complaint_period: null,
    court_complaint_overdue: null,
    court_user: null,
    auto_update_debtor_data: false,
    auto_update_debtor_data_period: null,
    auto_update_debtor_data_config: [],
    enrichment_by_address: false,
    enrichment_by_address_estates: false,
    debt_previous_rosreestr_movement: false,
    move_judicial_debtors_without_debt_to_archive: false,
    exclude_when_died: false,
    exclude_with_deregistration_date: false,

    auto_refiling_claims: false,
    auto_refiling_claims_period: null,
    auto_filing_isk: false,
    auto_filing_claim_by_no_debtor_info: false,
    auto_filing_claim_by_another_jurisdiction: false,
    pp3_counter: 0,
    send_electronic_court_decisions: false,
    send_electronic_court_decisions_notify_emails: [],
    sync_active_court_cases: false,
    sync_archive_court_cases: false,
    include_children: false,
    allow_without_court: false,
    short_penalty_calculation: false,
    show_automatic_attachments: false,
    auto_rosreestr_config: {},
    ignore_norights: false,
    rosreestr_user: 0,
  });

  const getPreparedModel = () => toSettings(model.value, employees.value);

  const emailOptions = ref<LabelValue[]>([]);

  const updateModel = (
    companySettings: CompanySettingsJudicial,
    employees: Employee[],
  ) => {
    const [employeeIds, notifyEmails] = (companySettings.notify_emails || [])
      .reduce<[number[], string[]]>((acc, email) => {
        const foundEmployee = employees.find((e) => e.email === email);
        if (foundEmployee) {
          acc[0].push(foundEmployee.id);
        } else {
          acc[1].push(email);
        }
        return acc;
      }, ([[], []]));
    emailOptions.value = notifyEmails.map((email) => ({ label: email, value: email }));
    // @ts-ignore
    model.value = toModel({
      ...companySettings,
      employees: employeeIds,
      notify_emails: notifyEmails,
    });
  };

  const onUpdateModelValue = (
    $event: any, fieldConfig: ActiveFormField<JudicialAutomationModel>,
  ) => {
    leaveController.markDirty();
    if (fieldConfig.onUpdateModelValue) {
      fieldConfig.onUpdateModelValue(model.value, $event);
    }
    if (!['notify_emails', 'send_electronic_court_decisions_notify_emails'].includes(fieldConfig.key)) {
      // @ts-ignore
      model.value[fieldConfig.key] = $event;
    }
  };

  return {
    model, onUpdateModelValue, updateModel, getPreparedModel, emailOptions,
  };
};
