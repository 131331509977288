import {
  computed, ref, Ref, unref, VNode, watch,
} from 'vue';
import {
  ListingRequestSource, ListingResponse, Order, OrderDirection,
} from '@/store/modules/api';
import { IActiveForm, ActiveFormModel } from '@/hooks/useActiveForm';
import { QueryTableParams } from '@/hooks/useInitQueryTableParams';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';
import { awaitFrame } from '@/utils/window';
import { SignalType, useSignal } from '@/hooks/useSignal';

export enum ActiveTableColumnFormat {
  string = 'string',
  date = 'date',
  dateYearMonth = 'dateYearMonth',
  dateTime = 'dateTime',
  money = 'money',
  number = 'number',
  phone = 'phone',
  duration = 'duration'
}

export enum ActiveTableColumnStick {
  left = 'stickLeft',
  right = 'stickRight',
}

export type ActiveTableColumnRender = (
  { record, index, fieldValue }: { record: Record<any, any>; index: number; fieldValue: any },
) => VNode[];

export type ActiveTableColumn<T extends Record<any, any>> = {
  key: string | keyof T | 'index';
  field?: keyof T | string | 'index';
  isSortable?: boolean;
  isRequired?: boolean;
  width?: number | string;
  label?: string;
  format?: ActiveTableColumnFormat|((value: any) => any);
  getValue?: (record: T) => any;
  allowEmpty?: boolean;
  stick?: ActiveTableColumnStick|false;
  headerClass?: string;
  id?: string;
}

export enum ActionType {
  default = 'default',
  subDefault = 'subDefault',
  line = 'line',
  side = 'side',
  record = 'record',
  context = 'context',
}

export type ActiveTableActionPayload<RecordT extends Record<any, any>, KeyField extends keyof RecordT> = {
  selectedItems: Array<RecordT[KeyField]>; // ids
  allSelected: boolean; // allOnAllPagesSelected
  records: Array<RecordT>;
  limit: number;
  page: number;
  total: number;
}

export type RowActionPayload<RecordT extends Record<any, any>, KeyField extends keyof RecordT> = {
  selectedItems: [RecordT[KeyField]];
  allSelected: false;
  records: Array<void>;
}

export type LoadingState = 'loading'|'loaded'

export type ActiveTableActionContext = {
  selectedItemIdList: Ref<any[]>;
  allOnAllPagesSelected: Ref<boolean>;
}

export type ActiveTableAction<RecordT extends Record<any, any>, KeyField extends keyof RecordT> = {
  key: string;
  label?: string;
  hint?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  types: Array<ActionType> | ActionType;
  group?: number;
  handler?(
    params: ActiveTableActionPayload<RecordT, KeyField>,
    record?: RecordT,
    tableContext?: ActiveTableActionContext,
  ): Promise<void> | void;
  check?(params: {record: RecordT; index: number}): boolean;
  // для групп
  checkIsDisabled?: ((params: { selectedRecords: RecordT[] }) => boolean);
  id?: string;
}

export type RowAction<RecordT extends Record<any, any>, KeyField extends keyof RecordT> =

  Omit<ActiveTableAction<RecordT, KeyField>, 'handler'> & {
    handler?(
      params: RowActionPayload<RecordT, KeyField>,
      record?: RecordT,
      tableContext?: ActiveTableActionContext,
    ): Promise<void> | void;
}

export enum ActiveTableState {
  default = 'default'
}

export type Fetch<T> = {
  params: ListingRequestSource<T>;
  signal?: AbortSignal;
}

export type FetchFn<T> = (
  { params, signal }: Fetch<T>
) => Promise<ListingResponse<T>>

export type ActiveTableInput<
  T extends Record<any, any>,
  RecordT extends Record<any, any>,
  KeyField extends keyof RecordT
> = {
  name?: string;
  keyField: KeyField;
  state?: ActiveTableState | Array<ActiveTableState>;
  getRecordRenderKey?: (record: RecordT) => string; // by default record[keyField]
  columns?: Ref<Array<ActiveTableColumn<T>>>;
  filters?: Ref<IActiveForm<T>['fields']>;
  mapFiltersModelToRequest?: (fModel: Record<any, any>) => Partial<T>;
  actions?: Ref<Array<ActiveTableAction<RecordT, KeyField>>>;
  defaultSort?: Ref<Array<Order<T>>|null>;
  defaultLimit?: Ref<number>;
  defaultPage?: Ref<number>;
  fetch(params: Fetch<T>): Promise<ListingResponse<RecordT>>;
  resetOnQuery?: boolean;
  summaries?: Ref<Array<Partial<keyof RecordT>>>; // keys
  initFilters?: QueryTableParams<T>['filters'];
  fetchInChunks?: boolean;
  preventInitFetch?: boolean;
  isRowExpandable?: boolean;
}

type Summary<RecordT> = Record<keyof Partial<RecordT> & any, number>; // why "& any"?

export type ActiveTable<
  T extends Record<any, any>,
  RecordT extends Record<any, any>,
  KeyField extends keyof RecordT
>
  =
  Omit<
    ActiveTableInput<T, RecordT, KeyField>
  , 'summaries' | 'fetch' >
   & {
  records: Ref<Array<RecordT>>;
  fetchData: () => Promise<void>;

  summariesFields?: Ref<Array<Partial<keyof RecordT>>>;
  summaries: Ref<Summary<RecordT>>;
  filtersModel: Ref<ActiveFormModel<T>>;
  resetFilters: () => void;

  total: Ref<number>;
  page: Ref<number>;
  limit: Ref<number>;
  sort: Ref<Array<Order<T>>>;

  isLoading: Ref<boolean>;
  isLoaded: Ref<boolean>;
  loadingState: Ref<LoadingState>;
  fetchPromise: Ref<Promise<void> | undefined>;
  awaitRecords: Ref<() => Promise<RecordT[]>>;

  loadingInChunks: Ref<boolean>;
  totalChunks: Ref<number>;
  currentChunk: Ref<number>;
}

export const useActiveTable = <
  T extends Record<any, any>,
  RecordT extends Record<any, any>,
  KeyField extends keyof RecordT,
  >({
    name,
    keyField,
    getRecordRenderKey,
    columns,
    actions = ref<Array<ActiveTableAction<T, KeyField>>>([]),
    filters = ref<IActiveForm<T>['fields']>([]),
    mapFiltersModelToRequest,
    defaultLimit = ref(0),
    defaultPage = ref(1),
    fetch,
    defaultSort = ref<Array<Order<T>>>([]),
    resetOnQuery,
    summaries: summariesFields,
    initFilters,
    preventInitFetch,
    fetchInChunks,
  }: ActiveTableInput<T, RecordT, KeyField> = {} as ActiveTableInput<T, RecordT, KeyField>): ActiveTable<T, RecordT, KeyField> => {
  const { dispatchSignal } = useSignal();
  const router = useProtectedInject(RouterKey);
  const records = ref<Array<RecordT>>([]);

  const summaries = ref<Summary<RecordT>>({});

  const limit = ref(defaultLimit?.value || 0);
  watch(defaultLimit, (newLimit) => {
    limit.value = newLimit;
  });
  const page = ref(defaultPage?.value || 1);
  watch(defaultPage, (newPage) => {
    page.value = newPage;
  });
  const total = ref(0);

  const sort = ref<Array<Order<T>>>(defaultSort?.value || [{
    key: keyField,
    direction: OrderDirection.asc,
  }]);
  watch(defaultSort, (newSort) => {
    sort.value = newSort as typeof sort.value;
  });

  const calcFiltersModel = <T>(localFilters: IActiveForm<T>['fields']): ActiveFormModel<T> => (
    localFilters.reduce((acc, cur) => {
      acc[cur.key] = cur.defaultValue ?? null;
      const initFilterValue = initFilters?.value?.[cur.key];
      if (initFilterValue !== null && initFilterValue !== undefined) {
        acc[cur.key] = initFilterValue;
      }
      return acc;
    }, {} as ActiveFormModel<T>)
  );
  const resetFilters = () => {
    filtersModel.value = calcFiltersModel(filters.value || []) as typeof filtersModel.value;
  };
  const filtersModel = ref<ActiveFormModel<T>>(calcFiltersModel(filters?.value || []));
  const filtersSerialized = computed(
    () => JSON.stringify(filters.value.map(
      (filter) => ({
        key: filter.key,
        defaultValue: unref(filter.defaultValue),
      }),
    )),
  );
  watch(filtersSerialized, (newFiltersSerialized, oldFiltersSerialized) => {
    if (newFiltersSerialized === oldFiltersSerialized) {
      return;
    }
    const newFilters = JSON.parse(newFiltersSerialized);
    filtersModel.value = calcFiltersModel(newFilters || []) as typeof filtersModel.value;
  });

  let abortController: AbortController | null;
  let awaitAbortFetch: Promise<void>|null;
  let awaitAbortPromiseResolver: (() => void)|null;
  let resultRecords: unknown[] | null = null;

  const isLoading = ref(false);
  const isLoaded = ref(false);
  const loadingInChunks = ref(false);
  const totalChunks = ref(0);
  const currentChunk = ref(0);

  function logName() {
    return `table${name ? ` "${name}":` : ''}`;
  }

  const fetchData = async () => {
    console.log(`${logName()} fetchData start`);

    if (resetOnQuery) {
      summaries.value = {};
      records.value = [];
    }
    if (abortController) {
      abortController.abort();
      if (awaitAbortFetch) {
        await awaitAbortFetch;
      }
    }
    abortController = new AbortController();
    isLoading.value = true;
    isLoaded.value = false;
    try {
      const CHUNK_SIZE = getFetchChunkSizeByLimit(limit.value, name?.includes('debtors') ?? false);
      const filtersModelFormatter = mapFiltersModelToRequest || ((model) => model);
      const filtersFormatted = filtersModelFormatter(
        filtersModel.value as any,
      ) as ActiveFormModel<T>;

      const hasActiveFilters = filters.value.filter((filter) => filter.isVisible || filter.isVisible === undefined).some(
        (filter) => {
          const value = filtersModel.value[filter.key];
          return Array.isArray(value) ? value.length : (value !== null && value !== undefined);
        },
      );

      if (!fetchInChunks
        || limit.value <= 100
        || (limit.value % CHUNK_SIZE !== 0)
      ) {
        const { count, results, total: summariesTotal } = await fetch({
          params: {
            limit: limit.value,
            page: page.value,
            ordering: sort.value,
            filters: filtersFormatted,
          },
          signal: abortController?.signal,
        });
        records.value = results as typeof records.value;
        total.value = count;
        if (summariesTotal) {
          summaries.value = summariesTotal;
        }
        resultRecords = results;
      } else {
        // const lastPageSize = limit.value % CHUNK_SIZE;

        const lastPage = (limit.value / CHUNK_SIZE) * page.value;
        const firstPage = lastPage - limit.value / CHUNK_SIZE + 1;

        records.value = [];
        summaries.value = {};

        let isAborted = false;
        const onAborted = () => {
          isAborted = true;
          loadingInChunks.value = false;
          totalChunks.value = 0;
          currentChunk.value = 0;
          console.log('fetchData: on aborted', name);
          abortController?.signal.removeEventListener('abort', onAborted);
        };
        abortController?.signal.addEventListener('abort', onAborted);
        awaitAbortFetch = new Promise<void>((resolve) => {
          awaitAbortPromiseResolver = resolve;
        });

        loadingInChunks.value = true;
        totalChunks.value = lastPage - firstPage + 1;
        currentChunk.value = 1;

        for (let currentPage = firstPage; currentPage <= lastPage; currentPage++) {
          currentChunk.value = currentPage - firstPage;
          if (isAborted) {
            break;
          }
          // eslint-disable-next-line no-await-in-loop
          await awaitFrame();
          // eslint-disable-next-line no-await-in-loop
          const { count, results, total: summariesTotal } = await fetch({
            params: {
              limit: CHUNK_SIZE,
              page: currentPage,
              ordering: sort.value,
              filters: filtersFormatted,
            },
            signal: abortController?.signal,
          });
          records.value.push(...results as typeof records.value);
          total.value = count;
          // костыль для таблицы должников
          dispatchSignal(SignalType.globalPreloaderDataIsLoaded);

          // самари расчитывается по всей выборке - сохраняем только один раз
          if (currentPage === firstPage && summariesTotal) {
            summaries.value = summariesTotal;
          }
          // нет необходимости запрашивать остальные чанки, если в них нет данных
          if (results.length < CHUNK_SIZE) {
            break;
          }
        }
        console.log('reset loading in chunks');
        abortController?.signal.removeEventListener('abort', onAborted);
      }
    } catch (e) {
      console.log(e);
      // Possibly aborted, who knows...
    }

    isLoading.value = false;
    isLoaded.value = true;
    loadingInChunks.value = false;
    totalChunks.value = 0;
    currentChunk.value = 0;
    if (awaitAbortPromiseResolver!) {
      awaitAbortPromiseResolver();
      awaitAbortFetch = null;
      awaitAbortPromiseResolver = null;
    }
    console.log(`${logName()} fetchData end`, { records: resultRecords });
  };

  const loadingState = computed<LoadingState>(
    () => {
      if (isLoading.value) return 'loading';
      return 'loaded';
    },
  );

  const promiseResolver = ref<() => void>();
  const fetchPromise = ref<Promise<void>>();

  const awaitRecords = ref<() => Promise<RecordT[]>>(doAwait);

  async function doAwait(): Promise<RecordT[]> {
    if (fetchPromise.value) {
      await fetchPromise.value;
      return records.value;
    }
    console.log(`${logName()} await records`);
    await setTimeout(() => {}, 100);
    return doAwait();
  }

  watch(fetchPromise, () => {
    awaitRecords.value = async () => {
      await fetchPromise.value;
      return records.value;
    };
  });

  watch(isLoading, (value) => {
    if (value) {
      fetchPromise.value = new Promise<void>((resolve) => {
        promiseResolver.value = resolve;
      });
    } else {
      promiseResolver.value?.();
    }
  });

  watch(limit, async () => {
    await router.isReady();
    if (page.value !== 1) {
      page.value = 1;
    } else {
      await fetchData();
    }
  }, {
    immediate: !preventInitFetch,
  });
  watch(page, fetchData);
  watch(
    computed(() => JSON.stringify(sort.value)),
    (newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }
      fetchData();
    },
  );

  watch(
    filtersModel,
    async (newValue, oldValue) => {
      if (page.value !== 1) {
        page.value = 1;
      }
      console.log(`${logName()} watch filters model`, oldValue, newValue);
      await fetchData();
    }, { deep: true },
  );

  const defaultRenderKeyMapper = (record: RecordT) => record[keyField];

  return {
    keyField,
    getRecordRenderKey: getRecordRenderKey ?? defaultRenderKeyMapper,
    records,
    columns,
    filters,
    filtersModel: filtersModel as Ref<ActiveFormModel<T>>, // ???
    resetFilters,
    actions,
    limit,
    defaultLimit,
    page,
    total,
    sort,
    fetchData,
    summariesFields,
    summaries,
    isLoading,
    isLoaded,
    loadingState,
    fetchPromise,
    awaitRecords,

    loadingInChunks,
    totalChunks,
    currentChunk,
  };
};

export const getDefaultListingRequestSource = <T>(key?: keyof T): ListingRequestSource<T> => ({
  page: 1,
  limit: 100000,
  ordering: key ? [{ key, direction: OrderDirection.asc }] : [],
  filters: {} as ActiveFormModel<T>,
});

export const formatListingRequest = <T>({
  ordering,
  page,
  limit,
  filters,
  offset,
}: ListingRequestSource<T>,
) => ({
    o: ordering?.reduce(
      (acc, cur) => {
        acc.push(`${cur.direction === 'asc' ? '' : '-'}${String(cur.key)}`);
        return acc;
      }, [] as string[],
    ) || undefined,
    limit,
    offset: offset ?? (page - 1) * limit,
    ...filters,
  }) || '';

export const formatListingResponse = <T>({
  count, results, total,
}: ListingResponse<T>, mapFunc?: (payload: T) => T): ListingResponse<T> => ({
    count,
    results: results?.map?.((result, index) => ({
      ...(mapFunc ? mapFunc(result) : result),
      index: (result as any).index ?? (index + 1),
    })) || [],
    total,
  });

function getFetchChunkSizeByLimit(limit: number, isDebtorsChunks: boolean) {
  if (isDebtorsChunks) {
    return 250;
  }
  if (limit >= 5000) {
    return 100;
  }
  if (limit >= 1000) {
    return 100;
  }
  if (limit === 250) {
    return 50;
  }
  return 100;
}
