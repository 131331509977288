import { computed } from 'vue';
import { useStore } from 'vuex';
import { ProductionType } from '@/hooks/useConstructor';
import { ApiResponse } from '@/store/modules/api';

export type UserPretrialActionsInterfacePrefs = {
  update: boolean;
  print: boolean;
  sms: boolean;
  voice: boolean;
  email: boolean;
  lock: boolean;
  unlock: boolean;
  settings: boolean;
  recognition: boolean;
  aisGorodUpdate: boolean;
  voice_call_queue: boolean;
  house_register: boolean;
  pikSaleDocuments: boolean;
};

export type UserJudicialActionsInterfacePrefs = {
  update: boolean;
  print: boolean;
  setOfCharges: boolean;
  fee: boolean;
  egrn: boolean;
  settings: boolean;
  recognition: boolean;
  attachEmployee: boolean;
  aisGorodUpdate: boolean;
  house_register: boolean;
  pikSaleDocuments: boolean;
};

export type UserExecutiveActionsInterfacePrefs = {
  update: boolean;
  print: true;
  recognition: boolean;
  house_register: boolean;
};

export type Columns = string[];

export type UserInterfacePrefs<T> = {
  actions: T;
  visible_columns?: Columns|null;
  default_debtors_table_filters?: Record<string, any>|null;
}

export type UserInterfacePrefsMap = {
  [ProductionType.pretrial]: UserInterfacePrefs<Partial<UserPretrialActionsInterfacePrefs>>;
  [ProductionType.judicial]: UserInterfacePrefs<Partial<UserJudicialActionsInterfacePrefs>>;
  [ProductionType.executive]: UserInterfacePrefs<Partial<UserExecutiveActionsInterfacePrefs>>;
}

export type UserInterfaceDefaultPrefsMap = {
  [ProductionType.pretrial]: UserInterfacePrefs<UserPretrialActionsInterfacePrefs>;
  [ProductionType.judicial]: UserInterfacePrefs<UserJudicialActionsInterfacePrefs>;
  [ProductionType.executive]: UserInterfacePrefs<UserExecutiveActionsInterfacePrefs>;
}

export type StandartizeAutomatitionSettings = ({
    type: 'page';
} | {
    type: 'range';
    pageFrom: number;
    pageTo: number;
} | {
    type: 'file';
}) & {
    enabled: boolean;
    minutes: number;
    withCadnums: boolean;
    force: boolean;
}

export type UserPrefsDefault = {
  debtor_per_page: number;
  interface_preferences: UserInterfaceDefaultPrefsMap;
  standartize_preferences: StandartizeAutomatitionSettings|null;
}

export type UserPrefs = {
  debtor_per_page: number;
  interface_preferences: Partial<UserInterfacePrefsMap>;
  standartize_preferences: StandartizeAutomatitionSettings|null;
}

export const getDefaultUserPrefs = () => ({
  debtor_per_page: 15,
  interface_preferences: {
    [ProductionType.pretrial]: {
      actions: {
        claim: true,
        update: true,
        print: true,
        sms: true,
        voice: true,
        email: true,
        lock: true,
        house_register: true,
        unlock: true,
        settings: true,
        attachEmployee: true,
        recognition: true,
        aisGorodUpdate: true,
        borUpdate: true,
        borUpdateFull: true,
        megapolisUpdate: true,
        pikUpdate: true,
        pikSaleDocuments: true,
        asuUpdate: true,
        voice_call_queue: true,
      },
      visible_columns: null,
    },
    [ProductionType.judicial]: {
      actions: {
        update: true,
        postal: true,
        fee: true,
        house_register: true,
        egrn: true,
        settings: true,
        setOfCharges: true,
        print: true,
        recognition: true,
        attachEmployee: true,
        aisGorodUpdate: true,
        borUpdate: true,
        borUpdateFull: true,
        megapolisUpdate: true,
        pikUpdate: true,
        pikSaleDocuments: true,
        asuUpdate: true,
      },
      visible_columns: null,
    },
    [ProductionType.executive]: {
      actions: {
        update: true,
        print: true,
        house_register: true,
        attachEmployee: true,
        recognition: true,
      },
      visible_columns: null,
    },
  },
  standartize_preferences: null,
}) as UserPrefsDefault;

export const useUserPrefs = () => {
  const store = useStore();
  const userPrefs = computed(() => store.getters['user/prefs']);

  const updateUserPrefs = (payload: Partial<UserPrefs>) => store.dispatch(
    'user/updatePrefs',
    payload,
  ) as unknown as ApiResponse<UserPrefsDefault>;

  return {
    userPrefs, updateUserPrefs,
  };
};

export const getDebtorsModuleTableModuleKey = (tableKey: string): false|ProductionType => {
  const [first, moduleProductionType] = tableKey.split('_');
  if (!first || first !== 'debtors') return false;
  if (
    !moduleProductionType || !Object.keys(ProductionType).includes(moduleProductionType)
  ) return false;
  return moduleProductionType as ProductionType;
};

export const isDebtorModuleActionPrefs = <T, K>(
  actions: any, tableKey: string, searchKey: K,
): actions is T => {
  const [first, moduleProductionType] = tableKey.split('-');
  if (!first || !moduleProductionType) return false;
  if (moduleProductionType === (searchKey as unknown as string)) return true;
  return false;
};

export const getMergedPrefs = (prefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => ({
  ...prefs,
  debtor_per_page: prefs.debtor_per_page || oldPrefs.debtor_per_page,
  interface_preferences: Object.entries(oldPrefs.interface_preferences)
  // @ts-ignore
    .reduce((acc, [productionKey, { actions, visible_columns, default_debtors_table_filters }]) => {
      const defaultActions = actions;
      const key = productionKey as ProductionType;
      const prefInterfacePrefs = prefs.interface_preferences
          // @ts-ignore
          && prefs.interface_preferences[key];

      // @ts-ignore
      const prefActions = (prefInterfacePrefs && prefInterfacePrefs.actions) || defaultActions;
      const prefVisibleColumns = prefInterfacePrefs?.visible_columns || null;
      const prefDefaultFilters = prefInterfacePrefs?.default_debtors_table_filters;
      return {
        ...acc,
        [productionKey]: {
          actions: { ...defaultActions, ...prefActions },
          visible_columns: prefVisibleColumns || visible_columns,
          ...(prefDefaultFilters !== null ? { default_debtors_table_filters: prefDefaultFilters || default_debtors_table_filters } : {}),
        },
      };
    }, {}),
} as UserPrefs);
export const getMergedPrefsWithDefault = (
  prefs: UserPrefs,
) => getMergedPrefs(prefs, getDefaultUserPrefs());
