import { Debtor } from '@/hooks/useDebtors';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import {
  VEHICLE_PAYMENT_CODES,
  VehiclePaymentCode,
} from '@/components/dialog/dialogs/debtor/tabs/finance/useTable/utils';
import {
  DebtorFinanceData,
  DebtorFinanceDataRecord,
  FullFinanceData,
  GetFinanceDataCommon,
  FinanceTabKey,
} from '@/components/dialog/dialogs/debtor/tabs/finance/types';
import { Ref } from 'vue';
import { excludeDots } from './excludeDots';

function parseNumber(value?: string) {
  return value ? parseFloat(value) : 0;
}

function getColumnsSumReducer(record: DebtorFinanceDataRecord) {
  const reducer = (acc: Record<string, number>, columnKey: string) => ({
    ...acc,
    [excludeDots(columnKey)]: record.parts
      .filter(({ title }: any) => title.startsWith(columnKey))
      .map(({ value }: any) => value)
      .reduce(
        (a: string, c: string) => parseNumber(a) + parseNumber(c),
        0,
      ),
  });
  return reducer;
}

function parseDate(value?: string) {
  return value ? new Date(value) : null;
}

export const getDebtorAccruals = (debtor: Debtor, financeData: DebtorFinanceData) => (
  financeData.accruals_data?.data
    ? financeData.accruals_data.data
      .map((record, index) => ({
        id: index + 1,
        date: parseDate(record.date),
        ...(
          record.parts?.length
            ? financeData.accruals_data.columns.reduce(getColumnsSumReducer(record), {})
            : {
              'Входящее сальдо': record.value,
            }
        ),
      })) : []
);

export const getDebtorVehicleAccruals = (
  codes?: VehiclePaymentCode[],
) => (debtor: Debtor, financeData: DebtorFinanceData) => {
  return financeData.vehicle?.trips
    ? [...financeData.vehicle.trips]
      .filter((trip) => (!codes ? true : codes?.includes(trip.payment_type)))
      .map((trip) => ({
        date: parseDate(trip.date),
        transaction_date: parseDate(trip.transaction_date),
        transaction: trip.transaction,
        cost: trip.cost,
        payment_type: trip.payment_type ? VEHICLE_PAYMENT_CODES[trip.payment_type].label : null,
        place: trip.place?.name,
        operator_name: trip.place?.operator_name,
      }))
    : [];
};

export const getDebtorPaidUps = (debtor: Debtor, financeData: DebtorFinanceData) => (
  (financeData.paid_ups_data?.data ?? [])
    .map((record, index) => ({
      id: index + 1,
      date: parseDate(record.date),
      ...financeData.paid_ups_data.columns.reduce(getColumnsSumReducer(record), {}),
    }))
);

type RecalcRecord = {
  date: string;
  title: string;
  value: number | string;
}

export const getDebtorRecalculations = (debtor: Debtor, financeData: DebtorFinanceData) => {
  const result: RecalcRecord[] = [];
  (financeData.recalculations_data || {}).data.forEach((item) => {
    item.parts.forEach((part) => {
      result.push({
        date: item.date,
        title: part.title,
        value: part.value,
      });
    });
  });
  return result.reverse();
};

export const getDebtorDebts = (debtor: Debtor, financeData: DebtorFinanceData) => {
  return (financeData.debts_data || []).map((item) => ({
    ...item,
    start_date: parseDate(item.start_date),
    end_date: parseDate(item.end_date),
  }));
};

export const getDebtorVehicleDebts = (debtor: Debtor, financeData: DebtorFinanceData) => {
  return (financeData.debts_data || []).map((item) => ({
    ...item,
    start_date: parseDate(item.start_date),
    end_date: parseDate(item.end_date),
    place: item.trip?.place?.name || null,
    operator_name: item.trip?.place?.operator_name || null,
  }));
};

export const getDebtorPenalties = (debtor: Debtor, financeData: DebtorFinanceData) => {
  return (financeData.penalties_data || []).map((item) => ({
    ...item,
    start_date: parseDate(item.start_date),
  }));
};

const getFee = (debtor: Debtor, financeData: DebtorFinanceData) => (
  [
    {
      start_date: financeData.penalties_data?.[0]?.start_date || null,
      value: debtor.total_fee,
    },
  ]);

const defaults = {
  accruals: getDebtorAccruals,
  'paid-ups': getDebtorPaidUps,
  recalculations: getDebtorRecalculations,
  debts: getDebtorDebts,
  penalties: getDebtorPenalties,
  fee: getFee,
  overdue_debt: getDebtorDebts,
};

const debtorFinanceDataMapper = {
  1: defaults,
  2: {
    accruals: getDebtorVehicleAccruals(),
    'paid-ups': getDebtorVehicleAccruals([2, 3]),
    debts: getDebtorVehicleDebts,
    penalties: getDebtorPenalties,
    fee: getFee,
    overdue_debt: getDebtorDebts,
  },
  3: defaults,
  4: defaults,
} as Record<
  CompanyModuleType, Record<
    FinanceTabKey, (debtor: Debtor, financeData: DebtorFinanceData) => Array<any>
    >
>;

export const mapFinanceData = ({
  debtor,
  financeData,
  companyModuleType,
  activeTab,
  fullData,
}: GetFinanceDataCommon & { debtor: Debtor; fullData: Ref<FullFinanceData> }) => {

  const map = debtorFinanceDataMapper[companyModuleType];
  (Object.keys(map) as FinanceTabKey[]).forEach((key) => {
    fullData.value[key] = map[key](debtor, financeData);
  });
  return fullData.value[activeTab] ?? [];
};
