import {
  ActionType, ActiveTableColumnFormat,
  formatListingRequest,
  useActiveTable,
} from '@/components/activeTable/useActiveTable';
import { computed, Ref, ref } from 'vue';
import { useVoiceCallQueueApi, VoiceCallQueue } from '@/hooks/useVoiceCallQueueApi';
import { useDialog } from '@/hooks/useDialog';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';

import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ListingResponse, OrderDirection } from '@/store/modules/api';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';

export const useVoiceCallQueue = () => {
  const {
    updateVoiceCallQueue,
    deleteVoiceCallQueue,
  } = useVoiceCallQueueApi();
  const { companyId } = useProtectedDefaultCompany();

  const { confirmDialog } = useDialog();
  const { t } = useLocalI18n('dialogs.voice_call_queue');

  const switchVoiceCallQueue = async (selectedItems: number[]) => {
    const recordId = selectedItems[0] as number;
    if (!recordId) {
      return;
    }
    const record = records.value.find((rec) => rec.id === recordId);

    if (record) {
      const response = await updateVoiceCallQueue({ stopped: !record.stopped }, record.id);

      if (response.status) {
        await refetch();
      }
    }
  };

  const {
    records,
    columns,
    actions,
    fetchData: refetch,
    limit,
    page,
    total,
  } = useActiveTable<VoiceCallQueue, VoiceCallQueue, 'id'>({
    keyField: 'id',
    defaultLimit: ref(20),
    defaultPage: ref(1),
    fetch: async ({ signal, params }) => {
      const { status, response } = await commonLegacyApiRequest<ListingResponse<VoiceCallQueue>>({
        command: ApiCommand.fetchVoiceCallQueues,
        params: formatListingRequest({
          ...params,
          filters: { ...params.filters },
          ordering: [{
            key: 'id',
            direction: OrderDirection.asc,
          }],
        }),
      });

      return {
        count: response.count,
        results: response.results.map((r: VoiceCallQueue) => ({
          ...r,
          scheduled: r.scheduled.toLocaleString(),
          called: r.called.toLocaleString(),
        })),
      };
    },
    filters: computed<Array<ActiveFormField<{company: number; id?: number}>>>(() => ([
      {
        key: 'company',
        field: 'company',
        type: ActiveFormFieldType.input,
        defaultValue: companyId.value,
        isVisible: false,
      },
    ])),
    columns: computed(() => (([
      {
        key: 'id',
        label: '№',
        width: 'minmax(30px, 0.2fr)',
      },
      {
        key: 'created_at',
        width: 'minmax(160px, 1.3fr)',
        format: ActiveTableColumnFormat.dateTime,
      },
      {
        key: 'is_auto',
        width: 'minmax(160px, 1.3fr)',
      },
      {
        key: 'scheduled',
        width: 'minmax(90px, 1fr)',
      },
      {
        key: 'called',
        width: 'minmax(80px, 1fr)',
      },
    ]).map((column) => ({
      ...column,
      field: column.key,
      label: column?.label ?? t(`column.${column.key}`),
    })))),
    actions: computed(() => ([
      {
        key: 'play',
        icon: 'play',
        label: 'Запустить',
        types: [ActionType.record],
        check: ({ record }): boolean => record.stopped,
        handler: async ({ selectedItems }: {
          selectedItems: number[];
        }) => {
          await switchVoiceCallQueue(selectedItems);
        },
      },
      {
        key: 'pause',
        icon: 'pause',
        label: 'Остановить',
        types: [ActionType.record],
        check: ({ record }): boolean => !record.stopped,
        handler: async ({ selectedItems }: {
          selectedItems: number[];
        }) => {
          await switchVoiceCallQueue(selectedItems);
        },
      },
      {
        key: 'remove',
        icon: 'trash',
        label: 'Удалить',
        types: [ActionType.record],
        handler: async ({ selectedItems }: { selectedItems: number[] }) => {
          const recordId = selectedItems[0] as number;
          if (!recordId) {
            return;
          }
          const record = records.value.find((rec) => rec.id === recordId);
          const confirmDialogResult = await confirmDialog({
            key: 'remove_voice_call_queue',
            title: 'Подтверждение удаления',
            message: 'Удалить очередь обзвона?',
            confirmLabel: 'Подтвердить',
            cancelLabel: 'Отмена',
          });
          confirmDialogResult.closeDialog();
          if (!confirmDialogResult.result) {
            return;
          }
          const response = await deleteVoiceCallQueue(record!.id as number);
          if (response.status) {
            await refetch();
          }
        },
      },
    ])),
  });

  return {
    records,
    columns,
    actions,
    limit,
    page,
    total,
  };
};
