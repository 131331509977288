import IntegrationGosuslugi
  from '@/pages/exchange/integration/dialogs/integrationGosuslugi/IntegrationGosuslugi.vue';
import IntegrationPochta
  from '@/pages/exchange/integration/dialogs/integrationPochta/IntegrationPochta.vue';
import IntegrationGosPochta
  from '@/pages/exchange/integration/dialogs/integrationGosPochta/IntegrationGosPochta.vue';
import IntegrationAutodor
  from '@/pages/exchange/integration/dialogs/integrationAutodor/IntegrationAutodor.vue';
import IntegrationRosreestr
  from '@/pages/exchange/integration/dialogs/integrationRosreestr/IntegrationRosreestr.vue';
import IntegrationSendPulse
  from '@/pages/exchange/integration/dialogs/integrationSendPulse/IntegrationSendPulse.vue';
import IntegrationSmsC
  from '@/pages/exchange/integration/dialogs/integrationSmsC/IntegrationSmsC.vue';
import IntegrationImobis
  from '@/pages/exchange/integration/dialogs/integrationImobis/IntegrationImobis.vue';
import IntegrationBilling
  from '@/pages/exchange/integration/billing/billingOnline/IntegrationBilling.vue';
import BillingMosvodocanal
  from '@/pages/exchange/integration/billing/mosvodocanal/index.vue';
import { NotificationSendType } from '@/hooks/useCompanySettingsSecure';
import AisGorod from '@/pages/exchange/integration/billing/AisGorod.vue';
import Megapolis from '@/pages/exchange/integration/billing/Megapolis.vue';
import Pik from '@/pages/exchange/integration/billing/Pik.vue';
import IntegrationKvint from '@/pages/exchange/integration/dialogs/IntegrationKvint.vue';
import IntegrationImap from '@/pages/exchange/integration/dialogs/IntegrationImap.vue';
import Asu from '@/pages/exchange/integration/billing/Asu.vue';
import IntegrationSmsTraffic
  from '@/pages/exchange/integration/dialogs/integrationSmsTraffic/IntegrationSmsTraffic.vue';
import IntegrationStreamTelecom
  from '@/pages/exchange/integration/dialogs/integrationStreamTelecom/IntegrationStreamTelecom.vue';
import IntegrationProstorSms
  from '@/pages/exchange/integration/dialogs/integrationProstorSms/IntegrationProstorSms.vue';

export enum IntegrationDialog {
  aisGorod = 'aisGorod',
  megapolis = 'megapolis',
  pik = 'pik',
  asu = 'asu',
  integrationGosuslugi = 'integrationGosuslugi',
  integrationAutodor = 'integrationAutodor',
  integrationKvint = 'integrationKvint',
  integrationImap = 'integrationImap',
  integrationPochta = 'integrationPochta',
  integrationGosPochta = 'integrationGosPochta',
  integrationRosreestr = 'integrationRosreestr',
  integrationSendPulse = 'integrationSendPulse',
  integrationProstorSms = 'integrationProstorSms',
  integrationSmsTraffic = 'integrationSmsTraffic',
  integrationStreamTelecom = 'integrationStreamTelecom',
  integrationSmsC = 'integrationSmsC',
  integrationImobis = 'integrationImobis',
  integrationBilling = 'integrationBilling',
  billingMosvodocanal = 'billingMosvodocanal',
}

export const integrationDialogs = {
  [IntegrationDialog.aisGorod]: AisGorod,
  [IntegrationDialog.megapolis]: Megapolis,
  [IntegrationDialog.pik]: Pik,
  [IntegrationDialog.asu]: Asu,
  [IntegrationDialog.integrationGosuslugi]: IntegrationGosuslugi,
  [IntegrationDialog.integrationPochta]: IntegrationPochta,
  [IntegrationDialog.integrationGosPochta]: IntegrationGosPochta,
  [IntegrationDialog.integrationAutodor]: IntegrationAutodor,
  [IntegrationDialog.integrationKvint]: IntegrationKvint,
  [IntegrationDialog.integrationImap]: IntegrationImap,
  [IntegrationDialog.integrationRosreestr]: IntegrationRosreestr,
  [IntegrationDialog.integrationSendPulse]: IntegrationSendPulse,
  [IntegrationDialog.integrationProstorSms]: IntegrationProstorSms,
  [IntegrationDialog.integrationSmsC]: IntegrationSmsC,
  [IntegrationDialog.integrationSmsTraffic]: IntegrationSmsTraffic,
  [IntegrationDialog.integrationStreamTelecom]: IntegrationStreamTelecom,
  [IntegrationDialog.integrationImobis]: IntegrationImobis,
  [IntegrationDialog.integrationBilling]: IntegrationBilling,
  [IntegrationDialog.billingMosvodocanal]: BillingMosvodocanal,
};

type CompanyPayload = {
  companyId: number;
}

type SendTypePayload = {
  type: NotificationSendType;
}

export type IDialogIntegration = {
  component: IntegrationDialog.aisGorod;
} | {
  component: IntegrationDialog.integrationGosuslugi;
} | {
  component: IntegrationDialog.integrationPochta;
} | {
  component: IntegrationDialog.integrationGosPochta;
} | {
  component: IntegrationDialog.integrationAutodor;
} | {
  component: IntegrationDialog.integrationKvint;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.integrationImap;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.integrationSendPulse;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationProstorSms;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationImobis;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationBilling;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.integrationSmsC;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationSmsTraffic;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationStreamTelecom;
  payload?: CompanyPayload & SendTypePayload;
} | {
  component: IntegrationDialog.integrationRosreestr;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.billingMosvodocanal;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.megapolis;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.pik;
  payload?: CompanyPayload;
} | {
  component: IntegrationDialog.asu;
  payload?: CompanyPayload;
}
